<template>
    <v-row>
        <v-col>
            <v-card class="rounded_card quaroshadow">
                <div class="backsheet"></div>
                <v-card-title> Onboarding-Video</v-card-title>
                <v-card-subtitle>Keyword-Research with Quaro </v-card-subtitle>
                <v-card-text>
                    <div class="video-block">
                        <iframe
                            src="https://www.youtube.com/embed/UI7JQO39fSE?cc_load_policy=1&hl=en&cc_lang_pref=en"
                            title="Keyword-Recherche mit Quaro - Demo-Video"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    export default {
        components: {},
        data() {
            return {}
        },
        mounted() {}
    }
</script>

<style></style>
