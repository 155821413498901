<template>
    <div id="contentcontainter" class="standard-mode" style="max-width: 1920px">
        <!-- <v-row>
            <v-col class="col-xl-9 col-md-9 col-sm-12 mx-auto"
                >selected: {{ model }} : {{ selected }}

                <component :is="selected.component" />
            </v-col>
            <v-col class="col-xl-3 col-md-3 col-sm-12">
                <v-row>
                    <v-col>
                        <v-card class="rounded_card quaroshadow">
                            <v-card-title style="background-color: #009f89; color: white">
                                <v-icon style="padding-bottom: 2px" class="mr-2" color="white">mdi-help-circle</v-icon>Knowledge Base</v-card-title
                            >
                            <v-navigation-drawer floating permanent>
                                <v-list rounded return-object>
                                    <v-list-item-group v-model="model" mandatory @change="getSelected()">
                                        <span v-for="item in doc_items" :key="item.title">
                                            <v-list-group v-if="item.items" dense no-action>
                                                <template v-slot:activator>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </template>

                                                <v-list-item v-for="child in item.items" :key="child.title">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ child.title }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-group>
                                            <v-list-item v-else link class="mb-1" v-model="item.active">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </span>
                                    </v-list-item-group>
                                </v-list>
                               
                            </v-navigation-drawer>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card class="rounded_card quaroshadow" style="background-color: #009f89; color: white" @click="">
                            <v-card-text class="white--text">
                                <h3 style="font-weight: 100"><v-icon color="white" class="mr-2">mdi-calendar</v-icon>Onboarding with Malte 45 min</h3>

                            </v-card-text>
                          
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card class="rounded_card quaroshadow" style="background-color: #009f89" @click="">
                            <v-card-text class="white--text">
                                <h3 style="font-weight: 100"><v-icon color="white" class="mr-2">mdi-video</v-icon>Onboarding Video 45 min</h3></v-card-text
                            >
                        
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row> -->
        <v-row>
            <v-col class="col-xl-8 col-md-8 col-sm-12 mx-auto">
                <v-row>
                    <v-col>
                        <v-card class="rounded_card quaroshadow">
                            <div class="backsheet"></div>
                            <v-card-title> Onboarding-Video</v-card-title>
                            <v-card-subtitle>Keyword-Research with Quaro </v-card-subtitle>
                            <v-card-text>
                                <div class="video-block">
                                    <iframe
                                        src="https://www.youtube.com/embed/UI7JQO39fSE?cc_load_policy=1&hl=en&cc_lang_pref=en"
                                        title="Keyword-Recherche mit Quaro - Demo-Video"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <!--  <v-row>
                    <v-col>
                        <v-card class="rounded_card quaroshadow">
                            <div class="backsheet"></div>

                            <v-card-title>Latest Updates</v-card-title>
                            <v-card-subtitle>Updated January 2022</v-card-subtitle>
                            <v-card-text class="px-10">
                                <v-list-item two-line class="pt-2">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-1-circle</v-icon>
                                                Questions-Modul
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext">
                                            In the Questions-Module, in addition to classic questions, prepositions and comparative words are now retrieved. For a simplified view and better sorting,
                                            we tag the keywords with the corresponding tags.
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>

                                <div class="img-con mb-10 flex">
                                    <img width="750" class="mx-auto" src="../assets/updatemeldung/questions_update_msg.png" />
                                </div>

                                <v-list-item two-line class="pt-1">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-2-circle</v-icon>
                                                Keyword-Trends
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext pt-1">In addition to the normal overview of keywords, there is now the overview <span class="bold">Keyword Trends"</span>.</p>
                                        <p class="subtext pt-2">
                                            Here the keywords are sorted by the Short Term Trend to <span class="bold">identify currently trending keywords</span>. Additionally, the keywords can be
                                            sorted by <span class="bold">monthly and yearly search volume change </span>, either by absolute or percentage change. The view for keyword trends can be
                                            changed in the tab at the top left in the keyword table.
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>

                                <div class="img-con mb-10 flex">
                                    <img width="750" class="mx-auto" src="../assets/updatemeldung/trend_update_msg.png" />
                                </div>

                                <v-list-item two-line class="pt-1">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-3-circle</v-icon>
                                                Peak/Season
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext pt-1">
                                            The Keyword Table gets two new columns: <span class="bold">Peak Month</span> and <span class="bold">Season</span>. The columns indicate which month of the
                                            year was the strongest and during which period of the year the keyword was searched for more than average and therefore has a season. The columns are<span
                                                class="bold">
                                                hidden by default and can be shown in the keyword table via Customize</span
                                            >.
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line class="pt-1">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-4-circle</v-icon>
                                                Save created columns
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext pt-1">
                                            Columns created by a user can now be saved as a favorite. This way, columns created once <span class="bold">can be reused in every module</span>.
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line class="pt-1">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-5-circle</v-icon>
                                                Keyword saved
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext pt-1">Besides each keyword, it is now displayed whether the keyword is saved in the clipboard or in one or multiple collections.</p>
                                    </v-list-item-content>
                                </v-list-item>

                                <div class="img-con mb-10 flex">
                                    <img width="750" class="mx-auto" src="../assets/updatemeldung/kwsaved__update_msg.png" />
                                </div>

                                <v-list-item two-line class="pt-1">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-6-circle</v-icon>
                                                Enter domain in SERP Preview
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext pt-1">To check the position of a website in the SERPs, a domain can be entered in the SERP Preview.</p>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line class="pt-1">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-7-circle</v-icon>
                                                Inviting users & company Domain
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext pt-1">
                                            New users can now be added by the admin directly through "Settings". In addition, the admin can register a company domain and change it, so that other users
                                            of the company can register independently.
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line class="pt-1">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-8-circle</v-icon>
                                                User settings
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext pt-1">
                                            Settings made by the user are now saved for the next session. This includes general chart settings, whether the user is in advanced or standard mode, dark
                                            mode, sorting, and the number of rows per page on the collections page.
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                            <v-card-title>November Update</v-card-title>
                            <v-card-subtitle>Updated November 2022</v-card-subtitle>
                            <v-card-text class="px-10">
                                <v-list-item two-line class="px-1 pt-2">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-1-circle</v-icon>
                                                Advanced/Global Filters
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext">
                                            The Advanced/Global Filters extend the existing filter options. Set filters remain active when you change pages, switch to collections or start a new
                                            search.
                                            <span class="bold"> (See video)</span>
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>

                                <div class="video-block">
                                    <iframe
                                        lazy
                                        width="auto"
                                        src="https://www.youtube.com/embed/_znzNQE3nyA"
                                        title="Quaro - New Features"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                </div>

                                <v-list-item two-line class="pt-5">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-2-circle</v-icon>
                                                Advanced Mode/SERP-Preview
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext pt-1">
                                            In addition to the classic functions, there is now also a SERP-Preview in Advanced Mode. Here, all
                                            <span class="bold"> 100 organic Google search results</span> for the requested keyword can be analyzed based on their
                                            <span class="bold"> page and domain rank as well as the estimated visits</span>.
                                        </p>
                                        <p class="subtext pt-2">
                                            In Compare Mode, the SERPs of <span class="bold"> two different keywords can be compared</span> with each other. An overlap of the SERPs is marked in color
                                            and displayed with a score (match in % from 0 to 100). Keywords with a high overlap can be used for one and the same content (landing page, video, ads
                                            campaign, ...).
                                            <span class="bold"> (See Video)</span>
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>

                                <div class="video-block">
                                    <iframe
                                        lazy
                                        width="auto"
                                        src="https://www.youtube.com/embed/UtEZfj0P4tw"
                                        title="Quaro - SERP-Preview"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                </div>

                                <v-list-item two-line class="pt-5">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-3-circle</v-icon>
                                                Historical Search Volume
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext pt-1">
                                            The Google Search Volume of the <span class="bold"> last 4 years is now available in all Modules</span>. The
                                            <span class="italic"> Historical Search Volume Module</span> has been removed accordingly.
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4>
                                                <v-icon class="pb-1" color="primary">mdi-numeric-4-circle</v-icon>
                                                Caching Page Views
                                            </h4>
                                        </v-list-item-title>
                                        <p class="subtext pt-1">
                                            The last 5 page views are now saved temporarily. This means that, for example, when switching between Ideas or Suggests, the last search is retained.
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>-->
            </v-col>
            <v-col class="col-xl-4 col-md-4 col-sm-12 pa-6">
                <v-card class="rounded_card quaroshadow">
                    <div class="backsheet"></div>
                    <v-card-text>
                        <!-- Calendly inline widget begin -->
                        <div
                            class="calendly-inline-widget"
                            data-url="https://calendly.com/quaro-meeting/onboarding?embed_domain=quaro.io&embed_type=Inline&hide_gdpr_banner=1&primary_color=009f87"
                            style="height: 850px"></div>
                        <!-- Calendly inline widget end -->
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import DemoVideo from "../components/knowledgebase/DemoVideo.vue"
    import LatestUpdates from "../components/knowledgebase/LatestUpdates.vue"
    import Clustering from "../components/knowledgebase/Clustering.vue"
    // import uuid from "uuid/v4"

    export default {
        components: {
            DemoVideo,
            LatestUpdates,
            Clustering
        },
        data() {
            return {
                model: 1,
                selected: null,
                doc_items: [
                    {
                        title: "User Interface",
                        active: false,
                        items: [
                            { title: "1. Collections", active: false, component: "DemoVideo" },
                            { title: "2. Keyword Table", active: false, component: "LatestUpdates" },
                            { title: "3. Metriken", active: false, component: "LatestUpdates" }
                        ]
                    },
                    { title: "Create a research", active: false, component: "DemoVideo" },
                    { title: "Ideas vs. Suggests", active: false, component: "" },
                    { title: "Question Tool", active: false, component: "" },
                    { title: "Categorization & Clustering", active: false, component: "Clustering" },
                    {
                        title: "Advanced Filter",
                        active: false,
                        items: [
                            { title: "1. Basic filter", active: false, component: "DemoVideo" },
                            { title: "2. Advanced filter", active: false, component: "DemoVideo" }
                        ],
                        component: ""
                    },
                    { title: "SERP Preview", active: false, component: "" },
                    { title: "Sharing", active: false, component: "" },
                    { title: "Import & Export", active: false, component: "" }
                ]
                // id: uuid(),
                // widget: undefined,
                // config: {
                //     selector: ".changelog",
                //     account: "xb9Ppy",
                //     callbacks: {
                //         onWidgetReady: (widget) => {
                //             this.widget = widget
                //         }
                //         // onShowWidget: () => this.$parent.$emit("close:header-items", [this.id])
                //     }
                // }
            }
        },
        computed: {
            activeC() {
                return
            }
        },
        methods: {
            getSelected() {
                // this.model is an index - we have to unwind all childs to extract the selected item
                console.log(this.model)

                let all = this.doc_items.map((item, index) => {
                    let obj = {}
                    obj.component = item.component
                    obj.title = item.title
                    return obj
                })

                let hasChilds = JSON.parse(JSON.stringify(this.doc_items.filter((item) => item.items)))
                hasChilds.map((item) => {
                    item.items.map((child, index) => {
                        let obj = {}
                        obj.component = child.component
                        obj.title = child.title
                        all.push(obj)
                    })
                })
                if (all[this.model].component) {
                    this.selected = all[this.model]
                }
                console.log(all)

                return
            }
        },
        mounted() {
            this.getSelected()
            const recaptchaScript = document.createElement("script")
            recaptchaScript.setAttribute("src", "https://assets.calendly.com/assets/external/widget.js")
            document.head.appendChild(recaptchaScript)

            // //Inject Headway app script
            // let headway = document.getElementById("headway-script")
            // if (headway) return //Script already appended

            // //Create promise which resolves on script load
            // let promise = new Promise((resolve, reject) => {
            //     let script = document.createElement("script")
            //     script.src = "//cdn.headwayapp.co/widget.js"
            //     script.id = "headway-script"
            //     script.setAttribute("async", true)
            //     document.head.appendChild(script)
            //     script.onload = resolve //Resolve when loaded
            //     script.onerror = reject
            // })
            // await promise //Await for the script to be resolved

            // //Init the widget now that the script has loaded
            // // eslint-disable-next-line no-undef
            // const waitForHeadway = () => {
            //     if (window["Headway"]) {
            //         window.Headway.init(this.config)
            //     } else {
            //         setTimeout(() => waitForHeadway(), 100)
            //     }
            // }
            // waitForHeadway()
            // console.log("loading finished")
        }
    }
</script>

<style>
    .video-block {
        width: 100%;
    }
    .fa-chevron-down {
        font-size: 5px;
    }
    .v-list-item__icon.v-list-group__header__append-icon > i {
        font-size: 12px;
    }
</style>
