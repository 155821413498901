<template>
    <v-row>
        <v-col>
            <v-card class="rounded_card quaroshadow">
                <div class="backsheet"></div>
                <v-card-title>Latest Updates</v-card-title>
                <v-card-subtitle>Updated November 2022</v-card-subtitle>
                <v-card-text class="px-10">
                    <v-list-item two-line class="px-1 pt-2">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-1-circle</v-icon>
                                    Advanced/Global Filters
                                </h4>
                            </v-list-item-title>
                            <p class="subtext">
                                The Advanced/Global Filters extend the existing filter options. Set filters remain active when you change pages, switch to collections or start a new search.
                                <span class="bold"> (See video)</span>
                            </p>
                        </v-list-item-content>
                    </v-list-item>

                    <div class="video-block">
                        <iframe
                            lazy
                            width="auto"
                            src="https://www.youtube.com/embed/_znzNQE3nyA"
                            title="Quaro - New Features"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>

                    <v-list-item two-line class="pt-5">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-2-circle</v-icon>
                                    Advanced Mode/SERP-Preview
                                </h4>
                            </v-list-item-title>
                            <p class="subtext pt-1">
                                In addition to the classic functions, there is now also a SERP-Preview in Advanced Mode. Here, all
                                <span class="bold"> 100 organic Google search results</span> for the requested keyword can be analyzed based on their
                                <span class="bold"> page and domain rank as well as the estimated visits</span>.
                            </p>
                            <p class="subtext pt-2">
                                In Compare Mode, the SERPs of <span class="bold"> two different keywords can be compared</span> with each other. An overlap of the SERPs is marked in color and
                                displayed with a score (match in % from 0 to 100). Keywords with a high overlap can be used for one and the same content (landing page, video, ads campaign, ...).
                                <span class="bold"> (See Video)</span>
                            </p>
                        </v-list-item-content>
                    </v-list-item>

                    <div class="video-block">
                        <iframe
                            lazy
                            width="auto"
                            src="https://www.youtube.com/embed/UtEZfj0P4tw"
                            title="Quaro - SERP-Preview"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>

                    <v-list-item two-line class="pt-5">
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-3-circle</v-icon>
                                    Historical Search Volume
                                </h4>
                            </v-list-item-title>
                            <p class="subtext pt-1">
                                The Google Search Volume of the <span class="bold"> last 4 years is now available in all Modules</span>. The
                                <span class="italic"> Historical Search Volume Module</span> has been removed accordingly.
                            </p>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>
                                    <v-icon class="pb-1" color="primary">mdi-numeric-4-circle</v-icon>
                                    Caching Page Views
                                </h4>
                            </v-list-item-title>
                            <p class="subtext pt-1">
                                The last 5 page views are now saved temporarily. This means that, for example, when switching between Ideas or Suggests, the last search is retained.
                            </p>
                        </v-list-item-content>
                    </v-list-item>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    export default {
        components: {},
        data() {
            return {}
        },
        mounted() {}
    }
</script>

<style></style>
