<template>
    <v-card class="quaroshadow rounded_card">
        <div class="backsheet"></div>
        <v-card-title class="mx-auto">
            {{ title }}
        </v-card-title>
        <v-card-subtitle>
            {{ subtitle }}
        </v-card-subtitle>
        <v-card-text>
            <v-row>
                <v-col class="flex">
                    <div style="max-width: 800px" class="ma-auto">
                        <video
                            style="width: 100%; border-radius: 10px"
                            class="video"
                            autoplay=""
                            loading="lazy"
                            loop=""
                            lazy=""
                            muted="muted"
                            playsinline=""
                            poster="https://quaro.io/wp-content/themes/quaro/images/custom_columns_screen.webp"
                            data-poster="https://quaro.io/wp-content/themes/quaro/images/custom_columns_screen.webp"
                            controlslist="nodownload">
                            <source
                                data-src="https://quaro.io/wp-content/themes/quaro/videos/cluster_video_big.mp4"
                                type="video/mp4"
                                src="https://quaro.io/wp-content/themes/quaro/videos/cluster_video_big.mp4" />
                            <source
                                data-src="https://quaro.io/wp-content/themes/quaro/videos/cluster_video_big.webm"
                                type="video/webm"
                                src="https://quaro.io/wp-content/themes/quaro/videos/cluster_video_big.webm" />
                        </video></div
                ></v-col>
            </v-row>
            <div class="pa-5" style="font-size: 16px">
                <p>Organize your keywords by individual columns. You can edit your keyword table using the "Customize" button. You can move columns, hide them or create new ones.</p>

                <p>When you create a new column, you can choose from 3 different column types:</p>

                <ul class="mb-3">
                    <li>Text field</li>
                    <li>Numbering field</li>
                    <li>Dropdown menu</li>
                </ul>

                <p>
                    The dropdown menu allows you to create different options (tags) and automatically cluster the keywords according to the created tags. You can save the created columns as favorites
                    and reuse them in new searches or in your collections.
                </p>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn class="ml-auto" color="primary" @click="$emit('next')">next</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                title: "Categorization & Clustering",
                subtitle: "How to categorize and cluster your Keywords"
            }
        }
    }
</script>

<style></style>
